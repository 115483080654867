<template>
  <v-navigation-drawer
    v-model="showDrawer"
    app clipped
    color="primary" dark
  >
    <div
      v-for="(group, index) in environmentGroups"
      :key="index"
      no-gutters
    >
      <v-row
        no-gutters
        class="accent py-2"
      >
        <v-icon v-text="getIcon(group)" color="primary" class="mx-4" />
        {{ group.name }}
      </v-row>
        <v-btn block text
          v-for="environment in group.environments"
          @click="showEnvironment(environment)"
          class="flat-btn"
          :key="environment.name"
        >
            {{ environment.name }}
        </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { checkRouteAndRedirect, clearFetch } from '@/helpers';

export default {
  name: 'navigationDrawer',
  props: {
    showDrawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('fetch', [
      'environment',
      'environmentGroups',
    ]),
  },
  methods: {
    ...mapActions({
      clearInterval: 'fetch/clearInterval',
    }),
    dark() {
      return this.$vuetify.theme.isDark;
    },
    getIcon(environmentGroup) {
      switch (get(environmentGroup, 'kind')) {
        case 'GS':
          return '$gameServer';
        default:
          return '$iris';
      }
    },
    async showEnvironment(environment) {
      // Check if we're already in that environment and, if not, redirect
      if (environment.name !== get(this, 'environment.name')) {
        // this.$emit('toggle');
        this.clearInterval();
        await clearFetch(this.$store); // cleanup environment state
        checkRouteAndRedirect(
          this.$router,
          environment.is_game_server ? 'environment' : 'k8s',
          {
            envName: get(environment, 'name'),
            id: get(environment, 'id'),
          },
        );
      }
    },
  },
};
</script>

<style scoped>
  .flat-btn {
    text-transform: none;
  }
</style>
